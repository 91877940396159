<template>
    <el-submenu
        :index="`${isIndex}`"
        :disabled="documentItems.all.length === 0"
        class="sidebar__nav-item submenu-documents"
    >
        <template slot="title">
            <nav-link :data="isData"/>
        </template>

        <el-menu-item-group v-loading="!isLoaded">
            <div class="sidebar__nav-loading">
                <el-tree
                    :data="treeDocArray"
                    :props="treeProps"
                    :load="treeLoadNode"
                    :allow-drop="allowDrop"
                    :draggable="isAdmin"
                    :default-expanded-keys="treeKeySet"
                    :lazy="true"
                    auto-expand-parent
                    @node-drag-start="onTreeHandleDragStart"
                    @node-drop="onTreeHandleDrop"
                    ref="tree"
                    node-key="id"
                    class="submenu-documents__tree"
                >
                    <nav-link
                        slot-scope="{ node, data }"
                        :external="{
                            status: data.is_href,
                            target: data.is_href ? '_blank' : '_self'
                        }"
                        :data="Object.assign(
                            {
                                name:    data.title,
                                link:    data.link,
                                is_type: data.is_href ? 'material' : 'rubric'
                            },
                            data
                        )"
                    />
                </el-tree>
            </div>
        </el-menu-item-group>
    </el-submenu>
</template>

<script>
    import session from '@/api/session';
    import { mapActions, mapGetters, mapState } from 'vuex';
    import { eventBus } from '@/main';
    import { useBroadcastChannel } from 'vue-composable';

    export default {
        name: 'NavTreeDoc',
        components: {
            NavLink: () => import('@/components/sidebar/navigation/NavLink')
        },
        props: {
            isData: {
                type: Object
            },

            isIndex: {
                type: String,
                default: 'menu_materials'
            },

            isOpen: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                isLoaded: false,
                broadCastDoc: {
                    supported: null,
                    data: { value: null },
                    send: null
                },
                treeKeySet: [],
                treeDocArray: [{}, {}, {}],
                treeDragNodeParams: {
                    data: {},
                    parent: {}
                },
                treeProps: {
                    label: 'title',
                    isLeaf: (data, node) => {
                        return (!data.children || !data.list);
                    }
                }
            };
        },

        watch: {
            isOpen(newVal, oldVal) {
                if (this.isLoaded === false) {
                    this.treeDocArray = JSON.parse(JSON.stringify(this.documentItems.all));
                    this.isLoaded = true;
                }
            },

            'broadCastDoc.data.value'(newValue, oldValue) {
                this.onBroadCastTree(newValue);
            }
        },

        computed: {
            ...mapState('documents_data', ['documentItems']),
            ...mapGetters('default_data', ['isAdmin'])
        },

        methods: {
            ...mapActions('documents_data', ['initialize', 'setDocuments']),

            onBroadCastTree({ drag, drop, type }) {
                if (type === 'inner') {
                    this.treeKeySet = [drop.id];
                    this.$refs.tree.remove(drag.id);
                    this.$refs.tree.append(drag, drop.id);
                }

                if (type === 'before' || type === 'after') {
                    this.$refs.tree.remove(drag.id);

                    if (type === 'before') this.$refs.tree.insertBefore(drag, drop.id);
                    if (type === 'after') this.$refs.tree.insertAfter(drag, drop.id);
                }
            },

            onTreeChange(dataTree = {}) {
                const node = this.$refs.tree.getNode(dataTree.id);

                if (node) {
                    const nodeData = node.data || {};
                    const newData = Object.assign(nodeData, {
                        id: dataTree.id,
                        title: dataTree.name,
                        emoji: dataTree.emoji || '',
                        is_href: nodeData.is_href
                    });

                    node.data = newData;
                }
            },

            onTreeRemove(dataTree = {}) {
                this.$refs.tree.remove(dataTree.id);
            },

            async onTreeHandleDragStart(draggingNode, ev) {
                ev.dataTransfer.dropEffect = 'move';
                ev.dataTransfer.effectAllowed = 'move';

                this.treeDragNodeParams.data = draggingNode.data;
                this.treeDragNodeParams.parent = draggingNode.parent;
            },

            async onTreeHandleDrop(draggingNode, dropNode, dropType, ev) {
                let dropResult = [];

                const dragElem = {
                    parent: this.treeDragNodeParams.parent,
                    type: this.treeDragNodeParams.data.is_href ? 'document' : 'rubric'
                };
                const keyNameObjects = {
                    id: this.treeDragNodeParams.data.is_href ? 'rubric_id' : 'parent_id',
                    items: this.treeDragNodeParams.data.is_href ? 'documents' : 'children'
                };

                /* Переложили во внутрь закрытой рубрики. */
                if (dropType === 'inner') {
                    dropResult = [
                        // old element
                        {
                            [keyNameObjects.id]: dragElem.parent.id || null,
                            [keyNameObjects.items]: this.utilChildArray(dragElem.parent.childNodes)
                        },

                        // new element
                        {
                            [keyNameObjects.id]: dropNode.data.id || null,
                            [keyNameObjects.items]: this.utilChildArray(dropNode.childNodes)
                        }
                    ];

                    this.treeKeySet = [dropNode.data.id];
                }

                /*
                    Просто переложили в другое место,
                    это может быть какая та отдельно-раскрытая рубрика
                    или же переставили элемент внутри главного блока(Документы).
                */
                if (dropType === 'after' || dropType === 'before') {
                    dropResult = [
                        // old element
                        {
                            [keyNameObjects.id]: dragElem.parent.data.id || null,
                            [keyNameObjects.items]: this.utilChildArray(dragElem.parent.childNodes)
                        },

                        // new element
                        {
                            [keyNameObjects.id]: dropNode.parent.data.id || null,
                            [keyNameObjects.items]: this.utilChildArray(dropNode.parent.childNodes)
                        }
                    ];
                }

                this.broadCastDoc.send({
                    type: dropType,
                    drag: draggingNode.data,
                    drop: dropNode.data
                });

                await this.utilPostDragDrop(dragElem.type, dropResult);
            },

            async utilPostDragDrop(type, array) {
                try {
                    if (type === 'rubric') {
                        await session.post(
                            '/api/v1/rubric_document/drag_and_drop_rubrics/',
                            array
                        );
                    }

                    if (type === 'document') {
                        await session.post(
                            '/api/v1/rubric_document/drag_and_drop_documents/',
                            array
                        );
                    }

                    // update document list
                    const response = await session.get('/api/v1/rubric_document/');
                    this.setDocuments({
                        rubrics: response.data.rubrics,
                        list: response.data.list
                    });
                } catch (error) {
                    console.error(error);

                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        title: 'Во время изменения положения документа/рубрики, произошла ошибка. Попробуйте ещё раз.',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        onOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer);
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                        }
                    });
                }
            },

            allowDrop(draggingNode, dropNode, type) {
                if (dropNode.data.is_href === true) {
                    return type !== 'inner';
                } else {
                    return true;
                }
            },

            async treeLoadNode(node, resolve) {
                const data = node.data || {};

                if (node.level === 0) {
                    return resolve(this.treeDocArray);
                }

                if (node.level > 0) {
                    const childrenArray = data.children || [];
                    const listArray = data.list || [];
                    const resultArray = [].concat(childrenArray, listArray);

                    return resolve(resultArray);
                }
            },

            utilChildArray(array) {
                return array.map((variable, index) => {
                    const data = variable.data || variable;

                    return {
                        id: data.id,
                        position: index
                    };
                });
            }
        },

        async mounted() {
            const { supported, data, send } = useBroadcastChannel('composable-doc-tree');

            this.broadCastDoc = {
                supported: supported,
                data: data,
                send: send
            };

            eventBus.$on('delete-document', (doc) => this.onTreeRemove(doc));
            eventBus.$on('changed-document', (doc) => this.onTreeChange(doc));

            await this.initialize();
        }
    };
</script>
